import { PopUpDomain } from '../domain/popup'
import { PopUpDto } from '../dto/popup'
import { FontFamily, ScreenDisplayType } from '../util/constants'

export const popUpTransformFromDtoToDomain = (data: PopUpDto): PopUpDomain =>
  new PopUpDomain(
    data.merchant_public_id,
    data.name,
    data.channels,
    data.type as ScreenDisplayType,
    data.enabled,
    data.id,
    data.logo,
    data.background_colour,
    data.button_colour,
    data.text_colour,
    data.button_text_colour,
    data.title_text,
    data.body_text,
    data.button_opt_in_text,
    data.button_close_text,
    data.legal_text,
    data.opt_in_response,
    data.corner_style,
    data.font_family as FontFamily
  )
