import { FontFamily, ScreenDisplayType } from '../util/constants'

export class PopUpDomain {
  merchantPublicId: string

  name: string

  channels: string[]

  type: ScreenDisplayType

  enabled: boolean

  id: string

  logo?: string

  backgroundColor?: string

  buttonColor?: string

  textColor?: string

  buttonTextColor?: string

  titleText?: string

  bodyText?: string

  buttonOptInText?: string

  buttonCloseText?: string

  legalText?: string

  optInResponse?: string

  cornerStyle?: string

  fontFamily?: FontFamily

  getFontFamily (): string {
    switch (this.fontFamily) {
      case FontFamily.TIMES_NEW_ROMAN:
        return "'Times New Roman', Times, serif"
      case FontFamily.ARIAL:
        return 'Arial, Helvetica, sans-serif'
      case FontFamily.MERRIWEATHER:
        return "'Merriweather', serif"
      case FontFamily.ROBOTO_MONO:
        return "'Roboto Mono', monospace"
      case FontFamily.ROBOTO:
      default:
        return "'Roboto', sans-serif"
    }
  }

  static toFontFamilyEnum (fontFamily: string): FontFamily {
    switch (fontFamily.toLowerCase()) {
      case 'times new roman':
        return FontFamily.TIMES_NEW_ROMAN
      case 'arial':
        return FontFamily.ARIAL
      case 'merriweather':
        return FontFamily.MERRIWEATHER
      case 'roboto mono':
        return FontFamily.ROBOTO_MONO
      case 'roboto':
      default:
        return FontFamily.ROBOTO
    }
  }

  constructor (
    merchantPublicId: string,
    name: string,
    channels: string[],
    type: ScreenDisplayType,
    enabled: boolean,
    id: string,
    logo?: string,
    backgroundColor?: string,
    buttonColor?: string,
    textColor?: string,
    buttonTextColor?: string,
    titleText?: string,
    bodyText?: string,
    buttonOptInText?: string,
    buttonCloseText?: string,
    legalText?: string,
    optInResponse?: string,
    cornerStyle?: string,
    fontFamily?: FontFamily
  ) {
    this.merchantPublicId = merchantPublicId
    this.name = name
    this.channels = channels
    this.type = type
    this.enabled = enabled
    this.id = id
    this.logo = logo
    this.backgroundColor = backgroundColor
    this.buttonColor = buttonColor
    this.textColor = textColor
    this.buttonTextColor = buttonTextColor
    this.titleText = titleText
    this.bodyText = bodyText
    this.buttonOptInText = buttonOptInText
    this.buttonCloseText = buttonCloseText
    this.legalText = legalText
    this.optInResponse = optInResponse
    this.cornerStyle = cornerStyle
    this.fontFamily = fontFamily
  }
}
