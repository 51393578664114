import styled from 'styled-components'

export const ModalFooter = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 0.875em;

  a {
    color: inherit;
    text-decoration: none;
    font-weight: 700;
  }
`
