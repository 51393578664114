import React from 'react'
import { TextInput } from './style'

interface InputProps {
  setFirstName: (val: string) => void
  value: string
}

const Input = ({ setFirstName, value }: InputProps): JSX.Element => {
  return (
    <TextInput
      onChange={(e) => setFirstName(e.target.value)}
      id='popup-input'
      value={value}
      placeholder='First name'
    />
  )
}

export default Input
