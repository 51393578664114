import { bgColor, bodyText, buttonCloseText, buttonColor, buttonOptInText, buttonTextColor, cornerStyle, fontFamily, isPreview, logo, popupId, popupName, textColor, titleText } from '..'
import { PopUpDomain } from '../domain/popup'
import { popUpTransformFromDtoToDomain } from '../transformers/popup'
import { RequestType, ScreenDisplayType } from '../util/constants'
import { request } from '../util/methods'

export enum ChannelType {
  SMS = 'sms',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp'
}
export interface Channel {
  channel_type: ChannelType
  handle: string
}
export interface SignUpForMarketingPayload {
  popup_settings_id: string
  name?: string
  surname?: string
  channels: Channel[]
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class PopUpService {
  public static async getSettings (popUpId: string): Promise<PopUpDomain> {
    if (popUpId === '') {
      return PopUpService.buildPreview()
    }

    const data = await request(RequestType.GET, `public/popup/${popUpId}`)

    if (isPreview === 'true') {
      return PopUpService.buildPreview(data?.data.merchant_public_id)
    }

    return popUpTransformFromDtoToDomain(data.data)
  }

  public static async signUpForMarketing (merchantPublicId: string, payload: SignUpForMarketingPayload): Promise<void> {
    await request(RequestType.POST, `public/marketing-preferences/signup/${merchantPublicId}`, payload)
  }

  public static buildPreview (merchantPublicId: string = '-11111'): PopUpDomain {
    return new PopUpDomain(
      merchantPublicId,
      popupName,
      [],
      ScreenDisplayType.BOTH,
      true,
      popupId,
      logo,
      bgColor,
      buttonColor,
      textColor,
      buttonTextColor,
      titleText,
      bodyText,
      buttonOptInText,
      buttonCloseText,
      '',
      '',
      cornerStyle,
      PopUpDomain.toFontFamilyEnum(fontFamily)
    )
  }
}
