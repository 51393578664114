import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import Popup from './components/Popup'
import { GlobalStyle } from './styles/global'

// css phone input

const style = document.createElement('link')
style.setAttribute('href', `${process.env.REACT_APP_POPUP_URL ?? 'localhost:3001'}/popup.css`)
style.setAttribute('rel', 'stylesheet')
document.head.appendChild(style)

const font = document.createElement('link')
font.setAttribute('rel', 'stylesheet')
font.setAttribute('type', 'text/css')
font.setAttribute(
  'href',
  'https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Roboto+Mono:wght@400;700&family=Roboto:wght@400;700&display=swap'
)
document.head.appendChild(font)

export const popupId =
  document.getElementById('fuzey-popup')?.getAttribute('popup-id') ?? ''

export const isPreview =
  document.getElementById('fuzey-popup')?.getAttribute('is-preview') ?? false

export const popupName = document.getElementById('fuzey-popup')?.getAttribute('name') ?? 'New Pop Up'

export const bgColor =
  document.getElementById('fuzey-popup')?.getAttribute('background-color') ??
  '#ededed'

export const buttonColor =
  document.getElementById('fuzey-popup')?.getAttribute('button-color') ??
  '#d9edfe'

export const textColor =
  document.getElementById('fuzey-popup')?.getAttribute('text-color') ??
  '#240e7c'

export const buttonTextColor =
  document.getElementById('fuzey-popup')?.getAttribute('button-text-color') ??
  '#2a2c2d'

export const titleText =
  document.getElementById('fuzey-popup')?.getAttribute('title-text') ??
  'Sign up for news and special offers!'

export const bodyText =
  document.getElementById('fuzey-popup')?.getAttribute('body-text') ??
  'This is a once in a lifetime opportunity as they call it...'

export const buttonOptInText =
  document.getElementById('fuzey-popup')?.getAttribute('button-opt-in-text') ?? 'Sign up'

export const buttonCloseText =
  document.getElementById('fuzey-popup')?.getAttribute('button-close-text') ?? 'No thanks'

export const cornerStyle =
  document.getElementById('fuzey-popup')?.getAttribute('corner-style') ?? 'rounded'

export const fontFamily =
  document.getElementById('fuzey-popup')?.getAttribute('font-family') ?? 'Roboto'

export const logo = document.getElementById('fuzey-popup')?.getAttribute('logo') ?? ''

const bootPopup = (): any => {
  // inject popup's base div if !preview. otherwise find the element with id popup root,

  const div = isPreview !== 'true'
    ? document.createElement('div')
    : (document.getElementById('fuzey-popup-root') as HTMLElement)

  if (isPreview !== 'true') {
    div.setAttribute('id', 'fuzey-popup-root')
    const body = document.body
    body.appendChild(div)
  }

  const root = ReactDOM.createRoot(div)

  root.render(
    <React.StrictMode>
      <GlobalStyle />
      <Popup />
    </React.StrictMode>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

if (document.readyState === 'complete') {
  bootPopup()
} else {
  window.addEventListener('load', bootPopup)
}
