import React, { useEffect } from 'react'
import { ModalTitle } from './style'

interface PopupFormProps {
  setDisplayModal: (val: boolean) => void
}

const PoupConfirmationContent = ({ setDisplayModal }: PopupFormProps): JSX.Element => {
  useEffect(() => {
    setTimeout(() => {
      setDisplayModal(false)
    }, 3000)
  }, [setDisplayModal])

  return (
    <ModalTitle id='popup-modal-confirmation'>
      {' '}
      Thank you for signing up to our mailing list
    </ModalTitle>
  )
}

export default PoupConfirmationContent
