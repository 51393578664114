import styled from 'styled-components'

interface InputProps {
  displayWarning: boolean
}

export const PhoneInputWrapper = styled.div<InputProps>`
  border-radius: 9999px;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 2.3rem;
  border: 1px solid ${(p) => p.displayWarning ? 'red' : '#cdcdcd'};

  &:focus,
  &:active,
  &:hover {
    border-color: #9496a9;
    box-shadow: #9496a9 0px 0px 0px 1px;
    outline: none;
  }

  .PhoneInput {
    height: 2.3rem;
    &Input {
      height: 2.3rem;
      border: none;
      outline: none;
      background-color: transparent;
      ::placeholder {
        color: grey;
      }
    }

  }
`
