import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    :root {
        // define global variables here
    } 
    
  #fuzey-popup-root::before,
  #fuzey-popup-root::after,
  #fuzey-popup-root *::before,
  #fuzey-popup-root *::after {
    all: unset;
  }
`
