import React from 'react'
import { ModalFooter } from './style'

const Footer = (): JSX.Element => {
  return (
    <ModalFooter>
      Powered by{' '}
      <a
        href='https://getfuzey.com'
        // onClick={() => send_analytics("powered_by_fuzey_opened")}
        rel='noreferrer'
        target='_blank'
      >
        Fuzey
      </a>
    </ModalFooter>
  )
}

export default Footer
