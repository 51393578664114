import React, { useEffect, useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import { isPreview } from '../..'
import { PopUpDomain } from '../../domain/popup'
import Footer from '../Footer'
import Logo from '../Logo'
import {
  CloseIcon,
  Modal,
  ModalContent,
  ModalHeader,
  ModalLogoArea
} from '../PopupModal/style'
import PopupFormContent from './PopupFormContent'
import PoupConfirmationContent from './PoupConfirmationContent'

interface PopupModalProps {
  setDisplayModal: (val: boolean) => void
  popUpSettings: PopUpDomain
}

const PopupModal = ({
  setDisplayModal,
  popUpSettings
}: PopupModalProps): JSX.Element => {
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false)

  useEffect(() => {
    setIsConfirmation(false)
  }, [])

  return (
    <>
      <Modal
        id='fuzey-popup'
        isPreview={isPreview === 'true'}
        onClick={() => {
          setDisplayModal(false)
        }}
        type={popUpSettings?.type}

      >
        <ModalContent
          bgColor={popUpSettings.backgroundColor}
          cornerStyle={popUpSettings.cornerStyle}
          textColor={popUpSettings.textColor}
          fontFamily={popUpSettings.getFontFamily()}
          onClick={(e) => e.stopPropagation()}
          id='popup-modal-content'
        >
          <ModalHeader>
            <CloseIcon type='button' onClick={() => setDisplayModal(false)}>
              <IoCloseOutline size='1.5em' />
            </CloseIcon>
          </ModalHeader>
          <ModalLogoArea>
            <Logo src={popUpSettings.logo} />
          </ModalLogoArea>

          {isConfirmation
            ? (
              <PoupConfirmationContent setDisplayModal={setDisplayModal} />
              )
            : (
              <PopupFormContent
                popUpSettings={popUpSettings}
                setDisplayModal={setDisplayModal}
                setIsConfirmation={setIsConfirmation}
              />
              )}

          <Footer />
        </ModalContent>
      </Modal>
    </>
  )
}

export default PopupModal
