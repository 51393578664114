import React from 'react'
import { WarningText } from './styles'

export type PossibleWarningKey =
  | 'empty_phone_number'
  | 'invalid_phone_number'

export type MultipleWarnings = {
  [key in PossibleWarningKey]?: string;
}

export interface WarningTextProps {
  key: PossibleWarningKey
  description: string
}

interface WarningTextComponentProps {
  isValid: boolean
  text?: string
}

const WarningTextComponent = ({
  isValid,
  text
}: WarningTextComponentProps): JSX.Element => {
  return <WarningText isValid={isValid}>{text}</WarningText>
}

export default WarningTextComponent
