import axios, { AxiosResponse } from 'axios'
import { apiUrl, RequestType } from './constants'

const api = axios.create({
  baseURL: apiUrl
})

export const request = async <T = any>(
  type: RequestType = RequestType.GET,
  path: string,
  payload = {},
  headers = {}
): Promise<AxiosResponse<T, any>> =>
  await api[type]<T>(
    `${path}`,
    {
      ...payload
    },
    { headers }
  )
