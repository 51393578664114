export const apiUrl =
  process.env.REACT_APP_FUZEY_API_URL ?? 'http://localhost:5004'

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum FontFamily {
  ROBOTO = 'Roboto',
  MERRIWEATHER = 'Merriweather',
  TIMES_NEW_ROMAN = 'Times New Roman',
  ARIAL = 'Arial',
  ROBOTO_MONO = 'Roboto Mono'
}

export enum ScreenDisplayType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  BOTH = 'both'
}
