import styled from 'styled-components'
import { FontFamily, ScreenDisplayType } from '../../util/constants'

export const ScreenSizes = {
  fullscreenMaxWidth: '429px',
  fullscreenMaxHeight: '429px',
  nonfullScreenMinWidth: '430px',
  nonfullScreenMinHeight: '430px'
}

interface ModalContentProps {
  bgColor?: string
  cornerStyle?: string
  textColor?: string
  fontFamily?: string
}
interface ConfirmButtonProps {
  btnColor?: string
  btnTextColor?: string
}

interface ModalProps {
  isPreview: boolean
  type?: ScreenDisplayType
}

export const Modal = styled.div<ModalProps>`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 999999;
  position: ${(p) => (p.isPreview ? 'relative' : 'fixed')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(p) => (p.type !== ScreenDisplayType.MOBILE ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  @media (max-width: ${ScreenSizes.fullscreenMaxWidth}),
    (max-height: ${ScreenSizes.fullscreenMaxHeight}) {
    display: ${(p) => (p.type !== ScreenDisplayType.DESKTOP ? 'flex' : 'none')};
  }
`

export const ModalContent = styled.div<ModalContentProps>`
  font-family: ${(p) => (p.fontFamily !== null ? p.fontFamily : FontFamily.ROBOTO)};
  width: 500px;
  background-color: #fff;
  text-align: center;
  padding: 10px 20px 0;
  background-color: ${(p) => p.bgColor};
  border-radius: ${(p) => (p.cornerStyle === 'rounded' ? '25px' : '')};
  color: ${(p) => p.textColor};

  // when it is mobile resolution, stretch popup to take full screen
  @media (max-width: ${ScreenSizes.fullscreenMaxWidth}),
    (max-height: ${ScreenSizes.fullscreenMaxHeight}) {
    border-radius: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`

export const ModalTitle = styled.div`
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`

export const ModalBody = styled.div`
  margin: 0 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ModalBodyText = styled.div`
  margin-bottom: 0.8rem;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalLogoArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

export const CloseIcon = styled.button`
  all: unset;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const SmallText = styled.div`
  font-size: 0.625rem;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
`

export const ConfirmButton = styled.button<ConfirmButtonProps>`
  width: 200px;
  text-transform: uppercase;
  color: ${(p) => p.btnTextColor};
  background-color: ${(p) => (p.btnColor !== null ? p.btnColor : '#d9edfe')};
  border:none;
  padding: 10px 20px;
  margin-bottom: 0.8rem;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const DeclineButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-bottom: 0.8rem;
  &:hover {
    opacity: 0.5;
  }
`
