import React from 'react'
import ReactPhoneNumberInput, {
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import WarningTextComponent from '../WarningText'
import { PhoneInputWrapper } from './styles'

interface PhoneInputProps {
  setPhoneNumber: (val: string) => void
  displayWarning: boolean
  warningText?: string
}

const PhoneInput = ({
  setPhoneNumber,
  displayWarning,
  warningText
}: PhoneInputProps): JSX.Element => {
  return (
    <div>
      <WarningTextComponent isValid={!displayWarning} text={warningText} />

      <PhoneInputWrapper displayWarning={displayWarning}>
        <ReactPhoneNumberInput
          international
          defaultCountry='GB'
          onChange={(e) => setPhoneNumber(e?.toString() ?? '')}
          id='popup-phone-input'
        />
      </PhoneInputWrapper>
    </div>
  )
}

export default PhoneInput
