import styled from 'styled-components'

export const TextInput = styled.input`
  border-radius: 9999px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-bottom: 0.8rem;
  height: 2.3rem;
  border: 1px solid #cdcdcd;
  background-color: inherit;

  &:focus,
  &:active,
  &:hover {
    border-color: #9496a9;
    box-shadow: #9496a9 0px 0px 0px 1px;
    outline: none;
  }

  ::placeholder {
    color: grey;
  }
`
