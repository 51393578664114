import styled from 'styled-components'

interface WarningTextProps {
  isValid: boolean
}
export const WarningText = styled.div<WarningTextProps>`
  font-size: 0.75rem;
  margin-bottom: 0.8rem;
  color: red;
  display: ${(props: WarningTextProps) => (props.isValid ? 'none' : '')};
`
