import React, { useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { PopUpDomain } from '../../domain/popup'
import PopUpService, { ChannelType } from '../../services/popup'
import Input from '../Input'
import PhoneInput from '../PhoneInput'
import { WarningTextProps } from '../WarningText'
import {
  ConfirmButton,
  DeclineButton,
  ModalBody,
  ModalBodyText,
  ModalTitle,
  SmallText
} from './style'

interface PopupFormProps {
  setDisplayModal: (val: boolean) => void
  setIsConfirmation: (val: boolean) => void
  popUpSettings?: PopUpDomain
}

const PopupFormContent = ({
  setDisplayModal,
  popUpSettings,
  setIsConfirmation
}: PopupFormProps): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [displayWarning, setDisplayWarning] = useState<boolean>(false)

  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  )

  useEffect(() => {
    setWarning(undefined)
  }, [])

  const onSubmit = async (): Promise<void> => {
    if (phoneNumber === '') {
      setDisplayWarning(true)
      setWarning({
        key: 'empty_phone_number',
        description: 'Phone number is required'
      })
    }

    if (phoneNumber !== '' && !isValidPhoneNumber(phoneNumber)) {
      setDisplayWarning(true)
      setWarning({
        key: 'invalid_phone_number',
        description: 'Please enter a valid phone number'
      })
    }

    if (isValidPhoneNumber(phoneNumber)) {
      setDisplayWarning(false)
      setWarning(undefined)

      if (typeof popUpSettings?.id === 'undefined') {
        return
      }

      setIsConfirmation(true)

      try {
        await PopUpService.signUpForMarketing(popUpSettings?.merchantPublicId, {
          name: firstName === '' ? undefined : firstName,
          popup_settings_id: popUpSettings?.id,
          channels: [{
            channel_type: ChannelType.SMS,
            handle: phoneNumber
          }]
        })
      } catch (signUpError) {
        console.error('Failed to sign up for marketing', signUpError)
      } finally {
        setIsConfirmation(false)
      }
    }
  }

  return (
    <>
      <ModalTitle id='popup-modal-title'>
        {popUpSettings?.titleText}
      </ModalTitle>
      <ModalBody>
        {(popUpSettings?.bodyText !== null) && (
          <ModalBodyText id='popup-modal-body-text'>
            {popUpSettings?.bodyText}
          </ModalBodyText>
        )}

        <Input value={firstName} setFirstName={setFirstName} />
        <PhoneInput
          setPhoneNumber={setPhoneNumber}
          displayWarning={displayWarning}
          warningText={warning?.description}
        />
        <SmallText id='popup-modal-legal-text'>
          {popUpSettings?.legalText ??
            '* I agree to receiving marketing text messages (e.g. about new products) at the phone number provided. Consent is not a condition to purchase. Message frequency varies. Reply STOP to cancel at anytime. View our Terms of Service and Privacy Policy'}
        </SmallText>
      </ModalBody>
      <div>
        <ConfirmButton
          id='popup-confirmation'
          btnColor={popUpSettings?.buttonColor}
          btnTextColor={popUpSettings?.buttonTextColor}
          type='submit'
          onClick={() => {
            void onSubmit()
          }}
        >
          {popUpSettings?.buttonOptInText}
        </ConfirmButton>
        <div>
          <DeclineButton
            id='popup-decline'
            type='button'
            onClick={() => setDisplayModal(false)}
          >
            {popUpSettings?.buttonCloseText}
          </DeclineButton>
        </div>
      </div>
    </>
  )
}

export default PopupFormContent
