import React, { useEffect, useState } from 'react'
import { isPreview, popupId } from '../..'
import { PopUpDomain } from '../../domain/popup'
import PopUpService from '../../services/popup'
import PopupModal from '../PopupModal'

const Popup = (): JSX.Element => {
  const [displayModal, setDisplayModal] = useState<boolean>(false)
  const [popUpSettings, setPopUpSettings] = useState<PopUpDomain | undefined>(
    undefined
  )

  const closeModalonEscape = (e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      setDisplayModal(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', closeModalonEscape)
    return () => {
      document.removeEventListener('keydown', closeModalonEscape)
    }
  }, [])

  useEffect(() => {
    const autoOpenPopUpStorageItem =
    window.sessionStorage.getItem(popupId)

    const shouldAutoOpenPopUp: boolean =
      isPreview === 'true' ||
      autoOpenPopUpStorageItem !== 'false' ||
      process.env.REACT_APP_POPUP_ALWAYS_OPEN === 'true'

    if (shouldAutoOpenPopUp) {
      PopUpService.getSettings(popupId).then((res) => {
        setPopUpSettings(res)
      }).catch((e) => {})
      setTimeout(() => {
        window.sessionStorage.setItem(popupId, 'false')
        setDisplayModal(true)
      }, isPreview === 'true' ? 0 : 2000)
    }
  }, [])

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {(isPreview === 'true' && (popUpSettings != null)) || (displayModal && popUpSettings?.enabled)
        ? (
          <PopupModal
            setDisplayModal={setDisplayModal}
            popUpSettings={popUpSettings}
          />
          )
        : null}
    </>
  )
}

export default Popup
