import React from 'react'
import { LogoImage } from './styles'

interface LogoProps {
  src?: string
}

const Logo = ({ src }: LogoProps): JSX.Element => {
  return (
    <>
      {src !== undefined ? <LogoImage alt='Merchant Logo' id='popup-logo' src={src} /> : null}
    </>
  )
}

export default Logo
